




















































































































































































































// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import Vue from 'vue';
import { LSS } from '../core/services';
import { decodeToken } from '@shared/utils/commom.util';
import { CB_Subs, CB_Customers, CB_ChangeSub } from 'src/utils/apptweakqueries';
import * as moment from 'moment';

export default Vue.extend({
  name: 'ManageSubscription',
  data() {
    return {
      isInTrial: false,
      trailEndDate: null,
      planName: null,
      nextBilling: null,
      dialog: false,
    };
  },

  methods: {
    async subscriptionDetailsFetch() {
      const custId = decodeToken(LSS.token).TeamID
      await this.$apollo
        .query({
          query: CB_Subs,
          variables: {
            custId: custId,
          },
        })
        .then((res) => {
          this.subscriptionDataCal(res.data.getSubsForCust.list[0]);
        })
        .catch((error) => {
          return error;
        });
    },

    subscriptionDataCal(subData) {
      const subsItems = [...subData.SubscriptionItems];
      const itemId = subsItems.filter(e => e.item_type == 'plan').map(e => e.item_price_id)[0];
      this.trailEndDate = subData.TrailEnd.split('T')[0];
      this.planName = itemId;
      if (subData.NextBillingAt) {
        this.nextBilling = subData.NextBillingAt.split('T')[0];
      }
    },

    openPortal() {
      var chargebeeInstance = Chargebee.getInstance();
      let cbPortal = chargebeeInstance.createChargebeePortal();
      cbPortal.open();
    },

    openPaymentSection() {
      var chargebeeInstance = Chargebee.getInstance();
      let cbPortal = chargebeeInstance.createChargebeePortal();
      cbPortal.openSection({
        sectionType: Chargebee.getPortalSections().PAYMENT_SOURCES
      })
    },

    openSubSection() {
      var chargebeeInstance = Chargebee.getInstance();
      let cbPortal = chargebeeInstance.createChargebeePortal();
      cbPortal.openSection({
        sectionType: Chargebee.getPortalSections().SUBSCRIPTION_DETAILS
      })
    },

    // getCustomerDetails() {
    //   const custId = 'AzZlEWStcolvc9D'
    //   this.$apollo
    //   .query({
    //     query: CB_Customers,
    //     variables: {
    //       id: custId
    //     }
    //   })
    //   .then(data => {
    //     console.log(data.data);
    //   })
    //   .catch(error => {
    //     console.log(error);
    //   });
    // },

    changeSubPlan() {
      this.$apollo
      .query({
        query: CB_ChangeSub,
        variables: {
          subId: 'AzqYWMStcrCxf8R'
        }
      })
      .then(data => {
        console.log(data.data);
        // if(data.data.changePlanForSub){
        //   alert('Bill Generated but unbilled. This Amount will be added to the next invoice! Refresh and check plan');
        // }else{
        //   alert('Bill generation failed! ');
        // }
      })
      .catch(error => {
        console.log(error);
        alert('Bill generation failed! ');
      });
    },
  },

  mounted() {
    if(decodeToken(LSS.token).subscriptionStatus == 'in_trial') {
      this.isInTrial = true
    }
    this.subscriptionDetailsFetch()
    //this.getCustomerDetails()
    //this.changeSubPlan()
  },
})
