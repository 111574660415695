var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dashboard" },
    [
      _c(
        "v-app",
        [
          _c("div", {
            staticClass: "inner-container only-heading-part header-with-banner",
          }),
          _c(
            "v-container",
            {
              staticClass:
                "v-main-content only-simple-content container--fluid  ",
              attrs: { fluid: "" },
            },
            [
              _vm.isInTrial
                ? _c(
                    "v-card",
                    { staticClass: "px-8 pt-6 pb-10" },
                    [
                      _c(
                        "div",
                        { staticClass: "box-header chart-header-area" },
                        [
                          _c("h3", { staticClass: "pl-0" }, [
                            _vm._v(
                              _vm._s(this.$t("manageSubscription.trialPeriod"))
                            ),
                          ]),
                        ]
                      ),
                      _c("h5", { staticClass: "primary--text pb-2 " }, [
                        _vm._v(
                          _vm._s(
                            this.$t("manageSubscription.trialPeriodExpire")
                          ) +
                            " " +
                            _vm._s(_vm.trailEndDate) +
                            " "
                        ),
                      ]),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            width: "150",
                            color: "primary",
                            depressed: "",
                            large: "",
                            dark: "",
                          },
                          on: {
                            click: function ($event) {
                              _vm.dialog = true
                            },
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(this.$t("manageSubscription.updateCard")) +
                              " "
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              !_vm.isInTrial
                ? _c(
                    "v-card",
                    { staticClass: "px-8 pt-6 pb-10" },
                    [
                      _c(
                        "div",
                        { staticClass: "box-header chart-header-area" },
                        [
                          _c("h3", { staticClass: "pl-0" }, [
                            _vm._v(
                              _vm._s(
                                this.$t("manageSubscription.subscriptionDetail")
                              )
                            ),
                          ]),
                        ]
                      ),
                      _c("h5", { staticClass: "primary--text " }, [
                        _vm._v(" " + _vm._s(_vm.planName) + " "),
                      ]),
                      _c("p", {}, [
                        _vm._v(
                          _vm._s(this.$t("manageSubscription.p1")) +
                            ": " +
                            _vm._s(_vm.nextBilling) +
                            ". " +
                            _vm._s(this.$t("manageSubscription.p2"))
                        ),
                      ]),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            width: "150",
                            color: "primary",
                            depressed: "",
                            large: "",
                            dark: "",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.openPortal()
                            },
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(this.$t("menu.manageSubscription")) +
                              " "
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-dialog",
                {
                  attrs: {
                    fullscreen: "",
                    "hide-overlay": "",
                    persistent: "",
                    transition: "dialog-center-transition",
                  },
                  model: {
                    value: _vm.dialog,
                    callback: function ($$v) {
                      _vm.dialog = $$v
                    },
                    expression: "dialog",
                  },
                },
                [
                  _c(
                    "v-card",
                    [
                      [
                        _c(
                          "v-container",
                          { staticClass: "h-100vh" },
                          [
                            _c(
                              "v-icon",
                              {
                                staticClass: "white--text close-icon",
                                on: {
                                  click: function ($event) {
                                    _vm.dialog = false
                                  },
                                },
                              },
                              [_vm._v("mdi-close")]
                            ),
                            _c(
                              "v-row",
                              {
                                staticClass: "primary",
                                staticStyle: { height: "25%" },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "w-100 d-flex flex-column align-center justify-center pa-4",
                                  },
                                  [
                                    _c(
                                      "h1",
                                      {
                                        staticClass:
                                          "font-weight-bold white--text",
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              this.$t(
                                                "manageSubscription.planListing"
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "font-weight-regular white--text text-center pb-12",
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              this.$t(
                                                "manageSubscription.furtherPlan"
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _c(
                              "v-row",
                              { staticClass: "justify-center plan-box" },
                              [
                                _c(
                                  "v-col",
                                  {
                                    attrs: {
                                      cols: "10",
                                      xl: "3",
                                      lg: "3",
                                      md: "4",
                                      sm: "6",
                                    },
                                  },
                                  [
                                    _c(
                                      "v-card",
                                      {
                                        staticClass:
                                          "rounded-lg pa-8 h-100 d-flex flex-column align-center justify-center",
                                        attrs: { elevation: "3" },
                                      },
                                      [
                                        _c(
                                          "h2",
                                          {
                                            staticClass: "font-weight-regular",
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                this.$t(
                                                  "manageSubscription.generalPlan"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "d-flex align-center mt-6",
                                          },
                                          [
                                            _c(
                                              "h1",
                                              {
                                                staticClass: "font-weight-bold",
                                              },
                                              [_vm._v("$500")]
                                            ),
                                            _c(
                                              "span",
                                              { staticClass: "mt-2" },
                                              [
                                                _vm._v(
                                                  "/" +
                                                    _vm._s(
                                                      this.$t(
                                                        "manageSubscription.month"
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "font-weight-regular primary--text mt-3",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  this.$t(
                                                    "manageSubscription.tryIt"
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "v-list",
                                          [
                                            _c(
                                              "v-list-item",
                                              [
                                                _c(
                                                  "v-list-item-content",
                                                  [
                                                    _c(
                                                      "v-list-item-title",
                                                      [
                                                        _c(
                                                          "v-icon",
                                                          {
                                                            staticClass:
                                                              "mr-3 secondary-dark",
                                                          },
                                                          [
                                                            _vm._v(
                                                              "mdi-checkbox-marked-circle-outline"
                                                            ),
                                                          ]
                                                        ),
                                                        _vm._v(
                                                          _vm._s(
                                                            this.$t(
                                                              "manageSubscription.createCampaign"
                                                            )
                                                          )
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-list-item",
                                              [
                                                _c(
                                                  "v-list-item-content",
                                                  [
                                                    _c(
                                                      "v-list-item-title",
                                                      [
                                                        _c(
                                                          "v-icon",
                                                          {
                                                            staticClass:
                                                              "mr-3 secondary-dark",
                                                          },
                                                          [
                                                            _vm._v(
                                                              "mdi-checkbox-marked-circle-outline"
                                                            ),
                                                          ]
                                                        ),
                                                        _vm._v(
                                                          _vm._s(
                                                            this.$t(
                                                              "manageSubscription.keyAnalysis"
                                                            )
                                                          )
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-list-item",
                                              [
                                                _c(
                                                  "v-list-item-content",
                                                  [
                                                    _c(
                                                      "v-list-item-title",
                                                      [
                                                        _c(
                                                          "v-icon",
                                                          {
                                                            staticClass:
                                                              "mr-3 secondary-dark",
                                                          },
                                                          [
                                                            _vm._v(
                                                              "mdi-checkbox-marked-circle-outline"
                                                            ),
                                                          ]
                                                        ),
                                                        _vm._v(
                                                          _vm._s(
                                                            this.$t(
                                                              "manageSubscription.automationRule"
                                                            )
                                                          )
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-list-item",
                                              [
                                                _c(
                                                  "v-list-item-content",
                                                  [
                                                    _c(
                                                      "v-list-item-title",
                                                      [
                                                        _c(
                                                          "v-icon",
                                                          {
                                                            staticClass:
                                                              "mr-3 secondary-dark",
                                                          },
                                                          [
                                                            _vm._v(
                                                              "mdi-checkbox-marked-circle-outline"
                                                            ),
                                                          ]
                                                        ),
                                                        _vm._v(
                                                          _vm._s(
                                                            this.$t(
                                                              "manageSubscription.keyOpt"
                                                            )
                                                          )
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              width: "250",
                                              color: "primary",
                                              depressed: "",
                                              large: "",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.openPaymentSection()
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  this.$t(
                                                    "manageSubscription.updateCard"
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }